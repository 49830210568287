import App from 'next/app';
import { ApolloProvider } from '@apollo/client';
import { useEffect, createContext, useState } from 'react';
import { useRouter } from 'next/router';
import { fetchAPI } from '../lib/api';
import { initializeApollo } from '../apollo/client';
import { ChakraProvider, extendTheme, useToast } from '@chakra-ui/react';
import '../styles/_globals.scss';
import '../styles/App.css';
import '../styles/index.scss';
import '../styles/sideNav.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-datepicker/dist/react-datepicker.css';
import CookieConsentBanner from '../components/CookieConsentBanner';
// import { GetUserLocation } from '../helpers';
import GoogleAnalytics from '../components/GoogleAnalytics';
import Zoho from '../lib/Zoho';
import { ID } from 'graphql-ws';

declare global {
  interface Window {
    GA_INITIALIZED: boolean;
    gtag: any; // Add this line to declare the gtag function
  }
}

// Store Global object in context
type NotificationType = {
  title: string;
  description: string;
  status: 'info' | 'warning' | 'success' | 'error' | 'loading' | undefined;
  duration: number;
  isClosable: boolean;
};

type DrawerType = {
  isOpen: boolean;
};

type GlobalDataType = {
  notification: NotificationType;
  user: any;
  company_id: number;
  selected_driver_id: number;
  selected_trip_id: number;
  tripDrawer: DrawerType;
  socket: any;
  isDarkMode: boolean;
  trip: any;
};

type GlobalContextType = [
  GlobalDataType | null,
  React.Dispatch<React.SetStateAction<GlobalDataType | null>>
];

const theme = extendTheme({
  colors: {
    brand: {
      50: '#11a00d',
      100: '#11a00d',
      200: '#11a00d',
      300: '#11a00d',
      400: '#11a00d',
      500: '#11a00d',
      600: '#11a00d',
      700: '#11a00d',
      800: '#11a00d',
      900: '#11a00d',
    },
  },
  components: {
    Alert: {
      variants: {
        solid: (props) => {
          const { colorScheme: c } = props;
          if (c === 'green') {
            return {
              container: {
                bg: 'brand.500', // Custom background color for success alerts
                color: 'white',
              },
            };
          }
        },
      },
    },
  },
});

// Store Global object in context
export const GlobalContext = createContext<GlobalContextType>([
  null,
  () => null,
]);

// Store Global SEO object in context
export const GlobalSeoContext = createContext({});

const MyApp = ({ Component, pageProps }) => {
  const [globalData, setGlobalData] = useState(null);

  const router = useRouter();

  const global = pageProps?.global?.find(
    (item) => item.attributes.page === 'global'
  );

  // console.log(global);
  const toast = useToast();

  useEffect(() => {
    if (globalData?.notification)
      toast({
        title: globalData.notification.title,
        description: globalData.notification.description,
        status: globalData.notification.status,
        duration: 5000,
        isClosable: globalData.notification.isClosable,
        onCloseComplete: () => {
          setGlobalData({ ...globalData, notification: null });
        },
      });
  }, [globalData]);

  let apolloClient = initializeApollo();

  // Load Facebook Pixel
  useEffect(() => {
    import('react-facebook-pixel')
      .then((x) => x.default)
      .then((ReactPixel) => {
        ReactPixel.init(process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID); // facebookPixelId
        ReactPixel.pageView();

        router.events.on('routeChangeComplete', () => {
          ReactPixel.pageView();
        });
      });
  }, [router.events]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setGlobalData((prevGlobalData: any) => ({
      ...prevGlobalData,
      company_id: Number(router.query.cid),
    }));
  }, [router]);

  return (
    <>
      <Zoho />
      <GoogleAnalytics />
      <ApolloProvider client={apolloClient}>
        <ChakraProvider theme={theme}>
          <GlobalContext.Provider value={[globalData, setGlobalData]}>
            <GlobalSeoContext.Provider value={global?.attributes}>
              <Component {...pageProps} />
              <CookieConsentBanner />
            </GlobalSeoContext.Provider>
          </GlobalContext.Provider>
        </ChakraProvider>
      </ApolloProvider>
    </>
  );
};

// getInitialProps disables automatic static optimization for pages that don't
// have getStaticProps. So article, category and home pages still get SSG.
// Hopefully we can replace this with getStaticProps once this issue is fixed:
// https://github.com/vercel/next.js/discussions/10949
MyApp.getInitialProps = async (ctx) => {
  // Calls page's `getInitialProps` and fills `appProps.pageProps`
  const appProps = await App.getInitialProps(ctx);

  // Fetch global site settings from Strapi
  const globalRes = await fetchAPI('/seos', {
    populate: {
      page: '*',
      seo: {
        populate: {
          metaImage: {
            populate: '*',
          },
          social: {
            populate: {
              image: {
                populate: '*',
              },
            },
          },
        },
      },
    },
  });

  // Pass the data to our page via props
  return { ...appProps, pageProps: { global: globalRes.data } };
};

export default MyApp;
